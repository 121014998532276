import React, { useContext, useState, useEffect } from 'react';
import { ProductContext } from '../contexts/ProductContext';
import Product from '../components/Product';
import Hero from '../components/Hero';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const Home = () => {
  // get products from product context
  let min = 0;
  let max = 2000;
  const { products } = useContext(ProductContext);

  const [priceRange, setPriceRange] = useState([min, max]);
  const [selectedTag, setSelectedTag] = useState('');
  const [filterProducts, setFilterProducts] = useState([]);
  const { apiTags, apiProducts, apiCategories } = useSelector(
    (state) => ({
      apiTags: state.brandReducer.brands,
      apiProducts: state.productReducer.products,
      apiCategories: state.categoryReducer.categories,
    }),
    shallowEqual
  );

  useEffect(() => {
    let newFilter = apiProducts;
    let newFitler2 = newFilter.filter(
      (ele) =>
        ele.priceAfterDiscount >= priceRange[0] &&
        ele.priceAfterDiscount <= priceRange[1]
    );
    if (selectedTag !== '') {
      setFilterProducts(
        newFitler2.filter((ele) => ele.brand._id === selectedTag)
      );
    } else {
      setFilterProducts(newFitler2);
    }
  }, [apiProducts, selectedTag, priceRange]);

  console.log(products);

  const handlePriceChange = (value) => {
    setPriceRange(value);
  };

  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const tagOptions = [
    { label: 'All', value: '' },
    ...apiTags.map((ele) => {
      return { label: ele.name, value: ele._id };
    }),

    // Add more options as needed
  ];
  return (
    <div>
      <Hero />
      <section className='py-20'>
        <div className='container mx-auto'>
          <h1 className='text-3xl font-semibold mb-10 text-center'>
            Explore Our Products
          </h1>
          <div className='flex justify-end'>
            <div className='p-4 flex align-middle items-center'>
              <span className='px-6'>{priceRange[0]}</span>
              <div style={{ width: '15rem' }}>
                <Slider
                  range
                  min={min}
                  max={max}
                  defaultValue={[min, max]}
                  onChange={handlePriceChange}
                />
              </div>
              <span className='px-6'>{priceRange[1]}</span>
            </div>
            <div className='p-4'>
              <select onChange={handleTagChange} className='p-2 border rounded'>
                {tagOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 lg:mx-8 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0 '
            style={{ minHeight: '20rem' }}
          >
            {filterProducts?.length > 0 ? (
              filterProducts.map((product) => {
                return <Product product={product} key={product.id} />;
              })
            ) : (
              <>No products found.</>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
