import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { BsPlus, BsEyeFill } from 'react-icons/bs';

import { CartContext } from '../contexts/CartContext';
import { publicUrl } from '../utils/api';
const Product = ({ product }) => {
  const { addToCart } = useContext(CartContext);

  // destructure product
  const { _id, thumbnailImage, category, name, priceAfterDiscount, brand } =
    product;
  return (
    <div>
      <div className='border border-[#e4e4e4] h-[300px] mb-4 relative overflow-hidden group transition'>
        <div className='w-full h-full flex justify-center items-center'>
          {/* thumbnailImage */}
          <div className='w-[200px] mx-auto flex justify-center items-center'>
            <img
              className='max-h-[160px] group-hover:scale-110 transition duration-300'
              src={publicUrl + thumbnailImage}
              alt=''
            />
          </div>
        </div>
        {/* buttons */}
        <div className='absolute top-6 -right-11 group-hover:right-5 p-2 flex flex-col justify-center items-center gap-y-2 opacity-0 group-hover:opacity-100 transition-all duration-300'>
          {/* <button onClick={() => addToCart(product, id)}>
            <div className='flex justify-center items-center text-white w-12 h-12 bg-teal-500'>
              <BsPlus className='text-3xl' />
            </div>
          </button> */}
          <Link
            to={`/product/${_id}`}
            className='w-12 h-12 bg-white flex justify-center items-center text-primary drop-shadow-xl'
          >
            <BsEyeFill />
          </Link>
        </div>
      </div>
      {/* category, name & price */}
      <div>
        <div className='tex-sm capitalize text-gray-500 mb-1'>
          {category?.name}
        </div>
        <div className='tex-sm capitalize text-gray-500 mb-1'>
          ({brand?.name})
        </div>
        <Link to={`/product/${_id}`}>
          <h2 className='font-semibold mb-1'>{name}</h2>
        </Link>

        <h2 className='font-semibbold'>$ {priceAfterDiscount}</h2>
      </div>
    </div>
  );
};

export default Product;
