import React from 'react';
import { useEffect, useLayoutEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import axiosInstance from '../../utils/api';
import productServices from '../../services/admin/productService';
import settingsService from '../../services/admin/settingsService';
import brandServices from '../../services/admin/brandService';
import categoryService from '../../services/admin/categoryService';
import { toast } from 'react-toastify';
import { ToastObjects } from '../../utils/toast/toastObject';

function MasterLayout() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { apiTags, apiProducts, apiCategories } = useSelector(
    (state) => ({
      apiTags: state.brandReducer.brands,
      apiProducts: state.productReducer.products,
      apiCategories: state.categoryReducer.categories,
    }),
    shallowEqual
  );

  useEffect(() => {
    try {
      brandServices.getAdminBrands(dispatch);
    } catch (error) {
      console.error(error);
    }
    try {
      categoryService.getCategories(dispatch);
    } catch (error) {
      console.error(error);
    }
    try {
      productServices.getProducts(dispatch);
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  return (
    <div className='master-layout'>
      <div>
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            classNames='page'
            timeout={500}
          >
            <Outlet />
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}

export default MasterLayout;
