import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';
import { ProductContext } from '../contexts/ProductContext';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { publicUrl } from '../utils/api';

const ProductDetails = () => {
  // get the product id from url
  const { id } = useParams();
  const { addToCart } = useContext(CartContext);
  const { apiTags, apiProducts, apiCategories } = useSelector(
    (state) => ({
      apiTags: state.brandReducer.brands,
      apiProducts: state.productReducer.products,
      apiCategories: state.categoryReducer.categories,
    }),
    shallowEqual
  );
  const product = apiProducts.find((item) => {
    return item._id === id;
  });
  console.log('-apiProducts', apiProducts);
  console.log(product);
  // if product is not found
  if (!product) {
    return (
      <section className='h-screen flex justify-center items-center'>
        Loading...
      </section>
    );
  }

  // destructure product
  // const { title, price, description, image } = product;
  const {
    _id,
    thumbnailImage,
    category,
    name,
    priceAfterDiscount,
    description,
    brand,
  } = product;
  return (
    <section className='pt-[450px] md:pt-32 pb-[400px] md:pb-12 lg:py-32 h-screen flex items-center'>
      <div className='container mx-auto'>
        {/* image and text wrapper */}
        <div className='flex flex-col lg:flex-row items-center'>
          {/* image */}
          <div className='flex flex-1 justify-center items-center mb-8 lg:mb-0'>
            <img
              className='max-w-[200px] lg:max-w-xs'
              src={publicUrl + thumbnailImage}
              alt=''
            />
          </div>
          {/* text */}
          <div className='flex-1 text-center lg:text-left'>
            <h1 className='text-[26px] font-medium mb-2 max-w-[450px] mx-auto lg:mx-0'>
              {name}
            </h1>
            <div className='text-2xl text-red-500 font-medium mb-6'>
              £ {priceAfterDiscount}
            </div>
            <div
              className='mb-8'
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {/* <p className='mb-8'>{description}</p> */}
            {/* <button
              onClick={() => addToCart(product, product.id)}
              className='bg-primary py-4 px-8 text-white'
            >
              Add to cart
            </button> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDetails;
