import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // headers: {
  //   Authorization: `Bearer ${TOKEN}`,
  // },
});

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       const dispatch = useDispatch();
//       dispatch(setToken(''));
//       // Unauthorized error, clear token and redirect to login page
//       window.location.href = '/login';
//     }
//     return Promise.reject(error);
//   }
// );

export const publicUrl = process.env.REACT_APP_BASE_URL;
export default axiosInstance;
